import { ɵgetAllInstancesOf, ɵisMessagingSupportedFactory, ɵgetDefaultInstanceOf, VERSION, ɵAngularFireSchedulers, ɵzoneWrap } from '@angular/fire';
import { timer, from } from 'rxjs';
import { concatMap, distinct } from 'rxjs/operators';
import * as i0 from '@angular/core';
import { InjectionToken, Optional, APP_INITIALIZER, NgModule, NgZone, Injector } from '@angular/core';
import { FirebaseApp, FirebaseApps } from '@angular/fire/app';
import { registerVersion } from 'firebase/app';
import { deleteToken as deleteToken$1, getMessaging as getMessaging$1, getToken as getToken$1, onMessage as onMessage$1 } from 'firebase/messaging';
export * from 'firebase/messaging';
class Messaging {
  constructor(messaging) {
    return messaging;
  }
}
const MESSAGING_PROVIDER_NAME = 'messaging';
class MessagingInstances {
  constructor() {
    return ɵgetAllInstancesOf(MESSAGING_PROVIDER_NAME);
  }
}
const messagingInstance$ = timer(0, 300).pipe(concatMap(() => from(ɵgetAllInstancesOf(MESSAGING_PROVIDER_NAME))), distinct());
const PROVIDED_MESSAGING_INSTANCES = new InjectionToken('angularfire2.messaging-instances');
function defaultMessagingInstanceFactory(provided, defaultApp) {
  if (!ɵisMessagingSupportedFactory.sync()) {
    return null;
  }
  const defaultMessaging = ɵgetDefaultInstanceOf(MESSAGING_PROVIDER_NAME, provided, defaultApp);
  return defaultMessaging && new Messaging(defaultMessaging);
}
function messagingInstanceFactory(fn) {
  return (zone, injector) => {
    if (!ɵisMessagingSupportedFactory.sync()) {
      return null;
    }
    const messaging = zone.runOutsideAngular(() => fn(injector));
    return new Messaging(messaging);
  };
}
const MESSAGING_INSTANCES_PROVIDER = {
  provide: MessagingInstances,
  deps: [[new Optional(), PROVIDED_MESSAGING_INSTANCES]]
};
const DEFAULT_MESSAGING_INSTANCE_PROVIDER = {
  provide: Messaging,
  useFactory: defaultMessagingInstanceFactory,
  deps: [[new Optional(), PROVIDED_MESSAGING_INSTANCES], FirebaseApp]
};
class MessagingModule {
  constructor() {
    registerVersion('angularfire', VERSION.full, 'fcm');
  }
}
MessagingModule.ɵfac = function MessagingModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MessagingModule)();
};
MessagingModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: MessagingModule
});
MessagingModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [DEFAULT_MESSAGING_INSTANCE_PROVIDER, MESSAGING_INSTANCES_PROVIDER, {
    provide: APP_INITIALIZER,
    useValue: ɵisMessagingSupportedFactory.async,
    multi: true
  }]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MessagingModule, [{
    type: NgModule,
    args: [{
      providers: [DEFAULT_MESSAGING_INSTANCE_PROVIDER, MESSAGING_INSTANCES_PROVIDER, {
        provide: APP_INITIALIZER,
        useValue: ɵisMessagingSupportedFactory.async,
        multi: true
      }]
    }]
  }], function () {
    return [];
  }, null);
})();
function provideMessaging(fn, ...deps) {
  return {
    ngModule: MessagingModule,
    providers: [{
      provide: PROVIDED_MESSAGING_INSTANCES,
      useFactory: messagingInstanceFactory(fn),
      multi: true,
      deps: [NgZone, Injector, ɵAngularFireSchedulers, FirebaseApps, ...deps]
    }]
  };
}
const isSupported = ɵisMessagingSupportedFactory.async;

// DO NOT MODIFY, this file is autogenerated by tools/build.ts
const deleteToken = ɵzoneWrap(deleteToken$1, true);
const getMessaging = ɵzoneWrap(getMessaging$1, true);
const getToken = ɵzoneWrap(getToken$1, true);
const onMessage = ɵzoneWrap(onMessage$1, false);

/**
 * Generated bundle index. Do not edit.
 */

export { Messaging, MessagingInstances, MessagingModule, deleteToken, getMessaging, getToken, isSupported, messagingInstance$, onMessage, provideMessaging };
